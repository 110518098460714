<template>
    <div>
<!--        <common-header>資訊詳情</common-header>-->
        <div class="m-wrapper04">
            <div class="m-detail01">
                <h4 class="article-tit02">{{currentNews.title}}</h4>
                <div class="m-article-info01">
                    <span class="article-tag01" :class="`tag-${itemObj.color}`">{{currentNews.type}}</span>
                    <span class="article-time01">{{currentNews.time}}</span>
                </div>
                <img class="news-img" :src="newsImg[itemObj.id]" alt="">
                <div class="detail-con03" v-html="currentNews.content">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {newsListDetail} from '@/constant/constant'
// import CommonHeader from '@/components/header'
export default {
    data() {
        return {
            itemObj: {
                id: '',
                color: ''
            },
            currentNews: {},
            newsImg: {
                1: require('@/assets/images/news-2.png'),
                2: require('@/assets/images/news-3.png'),
                3: require('@/assets/images/news-1.png'),
                4: require('@/assets/images/news-4.png'),
                5: require('@/assets/images/news-5.png')
            }
        }
    },
    components: {
        // CommonHeader
    },
    mounted() {
        this.itemObj = this.$route.query
        this.currentNews = newsListDetail[this.$route.query['id']]
    }
}
</script>
<style lang="scss" scoped>
.m-wrapper04 {
    background: #ffff;
    text-align: left;
    min-height: 100vh;
    padding-top: 0px;

    h3 {
        margin: 10px 0;
    }

    .news-img {
        width: 100%;
        margin-top: 10px;
    }
}
</style>
